.body{
  font-family: 'Lato', sans-serif;
 
}


.h3one{
  padding-left: 20px;
}

.top-div{
  border-radius: 1px;
    background-color: #fbfbfa;
    padding: 20px;
    width: 82.7vw;
    display: flex;
    flex-direction: row;
    margin-left: -28px;
    
}

.addlink{

  text-decoration: none;
  cursor: pointer;
  color: #428bca;
    background-color: transparent;
    padding-left: 20px;
   
}


.BsHandIndex{
  
  height: 30px;
  width:150px;

  
}


.first-div{
  display: flex;
  flex-direction: column;
  width:50%;
  padding-left: 20px;
 
}


  

.fileattach{ 
  height: 200px;
  width: 250px;
  box-sizing: border-box;
  margin-top: 10px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  padding: 20px;
  border-style: dotted;
  background-color: #fff;
  
  
}
.second-div{
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  
   
}

 
 .radiotwo{
  margin-right: 100px;
  margin: 0 10px 0 10px;
 }
 
 .radio{
  margin-right: 100px;
  margin: 0 10px 0 0px;
  
 }
 
.editbut{
  margin-left: 75rw;

}
.sk{
  padding-left: 20px;
}

.sku{
  padding-left: 32px;
  margin-top:15px;
  padding-bottom: 5px;
}
.editbutt{
  float:right
}

 
.col-25radio{

  float: left;
  width: 25%;
   margin-top: -1px;
   font-size: 14px;

}




 .h{

  
    width: 32%;
    margin-left: 10px;
    margin-right: auto;
      
   }
 .hrtwo{
  width: 73%;
  margin-left: 10px;
  margin-right: auto;

 }
.ad{
  padding-left: 18px;
}
 
 .hr{
  
  width: 67%;
  margin-left: 10px;
  margin-right: auto;
    
 }
 
 
 


.input-butt{
  margin-right: 30px;
  padding: 0.6rem ;
  outline: none;
  align-self: left;
  border: 0;
  color: #fff;
  border-radius: 10px;
  background: #ebe8e7;
  transition: 0.3s;
  cursor: pointer;
  

}
.checkboxone{
  margin: 0 8px 0 8px;
  font-size: 14px;
  
}
.checkbox{
  margin: 0 8px 0 8px;
  
  
}

  .dimension-fields {
    display: flex;
    border: 1px solid #b0c0d6;
    width:50%;
    
}
.dim{
  width:10px;
  /* display:none; */
    /* visibility:hidden; */
}
 
.container7{
  padding:0px;
  padding-left: 0px;
  width: 100%;
  border-collapse: collapse;
}

/* .input-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
 padding: 5px;
width: 65vw;

} */
.input-block1 {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  transition: 0.3s;
margin-right: 70px;
padding-left: 30px;
}



.weightt-box{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-top: 2px;
  padding-left: 19px;
  
}
.weightone{
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width:34vw;
  
}
.weighttwo{
  
   /* padding-left: 0px; */
  width:52vw; 
  margin-left: 30px;
 

}
.col-200{
  float: left;
  width: 36%;
   /* margin-top: 6px; */

}
.ra{
  position: relative;
    display: block;
    padding-bottom: 3px;
    padding-left: 0px;
    
 }


 .rao{
  position: relative;
    display: block;
    padding-bottom: 11px;
    padding-left: 0px;
 }
 .col-70{
  float:right;
  width:71%;
  margin-bottom: 10px;
  padding-left: 30px;
 
  
}


/* .col-705 input[type="number"], input[type="date"] {
  width:320px;
  height: 68%;
  padding: 7px 12px;
  margin: 5px ;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
} */ */
.col-708{
  float:right;
  width:70%;
  margin-bottom: 10px;
  padding-left: 30px;
 
  
}

.col-708 .inputselect{
  width: 265px;
    height: 76%;
    padding: 6px 12px;
    margin: 5px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
.inputselecttext{
  width: 265px;
    height: 100%;
    padding: 6px 12px;
    margin: 5px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 6px 10px;
 
    
  
}
.col-70.input{
  /* margin-left: 30px; */
  width:315px;
  height: 68%;
  padding: 7px 12px;
  margin: 5px ;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.thhead{
  width:25vw;
 }
 .col-20 {
    
  float: left;
  width: 40%;
   margin-top: 6px;
}
.purchasebox{
      
  display: flex;
  flex-direction: column;
 
 
   
    padding-left: 2px;
  
  justify-content: space-between;
  width: 55vw;

  
}

.purchaseebox{
  
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 13px;
  padding-left: 19px;
  margin-left:20px;
  
}

.col-26{ float: left;
  width: 25%;
   margin-top: 6px;

}
.row{ 
  display: flex;
flex-direction: row;}

.roww{ 
  display: flex;
flex-direction: row;}

.rowo{ 
  display: flex;
flex-direction: row;
padding-bottom: 0px;
width:35vw;
}



.box{

  display: flex;
   flex-direction:column ;
  
padding-left: 20px;
justify-content: space-between;
  width:34vw;


}
.boxoneon{
  width:52vw; 
  margin-left: -1000px;
  /* width:50%;
  padding-left:25px; */
}



.boxone{
  width:52vw; 
  margin-left: 20px;
  /* width:50%;
  padding-left:25px; */
}
.left {
  /* font-size: medium; */
 
  font-weight: 600;
  letter-spacing: 0.7px;
  color: #141414;
  transition: 0.3s;
}
.in{

width: 320px;
height: 56%;
padding: 6px 12px;
margin: 5px 0;
display: inline-block;
border: 1px solid #ccc;
border-radius: 4px;
box-sizing: border-box;
}
.input-buttonone{
  


margin-top:10px;
margin-left:10px;
  margin-right: 30px;
  padding: 0.6rem ;
  outline: none;
  
  border: 0;
  color: #fff;
  border-radius: 10px;
  background: #d56123;
  transition: 0.3s;
  cursor: pointer;
  
  font-family: "Nunito", sans-serif;
}

 .input-buttononeone{
  padding: 0.6rem ;
  outline: none;
  transition: 0.3s;
  cursor: pointer;
  color:#141414;
  border: 0;
  border-radius: 10px;
  margin-top:10px;
  
  background-color: #f2f2f2;
  
 }


.inbut{
 
  
  position: fixed;
  bottom: 0;
  margin: 0 0 0 0px;
  box-shadow: 0 -4px 5px -3px rgb(0 0 0 / 10%);
  
  width: calc(100% - 200px);
  z-index: 3;
  height:50px;
  



}


 .update{
  padding-top: 10px;
  padding-left: 700px;
  padding-bottom: 10px;
  background-color: #fbfbfa;
 }
 


  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  

  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }  
  @media  screen and (max-width: 600px) {
    .container{
      padding-left: 3px;
    }
    
    
    #inventory-adjustments{
      width:85vw;
      font-size: 0.7rem;
     }
  
     #inventory-adjustments td, #inventory-adjustments th {
  
      border: 1px solid #ddd;
    padding: 2px;
    text-align: center;
  
     }
  
     #inventory-adjustments th {
      padding-top: 2px;
      padding-bottom: 2px;
      text-align: center;
      background-color: #f67638;
      color: white;
      text-align: center;
      font-size: 0.7rem;
    }
    
    .top-div{
      width:100vw;
      display:flex;
      flex-direction: column;

    }
    .first-div{
      width:50vw;
  display: flex;
  flex-direction: column;

    }
    
    .fileattach{
      height: 100px;
    width: 130px;
    box-sizing: border-box;
    margin-top: 10px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    padding: 20px;
    border-style: dotted;
    background-color: #fff;
    padding-left: 1px;

    }
    
    
    .second-div{
      display: flex;
      padding-left: 100px;
    }
   
    
  }
  
 
  @media  screen and (max-width: 500px) {
    
    .container{
      padding-left: 2px;
    }
    
   
    .container1{
      width:100vw;
    }

    .title1{
      align-items: center;
    }
    .top-div{
      width:100vw;
      display:flex;
      flex-direction: column;
    }
    .second-div{
      display: flex;
    }
.first-div{
  width:50vw;
  display: flex;
  flex-direction: column;
}


.fileattach{
  width:50px;
  height: 50px;
  padding-top: 50px;
}

.input-buttononeone{
  margin-top: 20px;
}
   .fourth-box{
    padding-left: 0px;
   }
  

   #inventory-table{
    padding-left: 2px;
   }


   #inventory-adjustments{

    width:100vw;
    font-size: 0.7rem;

   }

   #inventory-adjustments td, #inventory-adjustments th {

    border: 1px solid #ddd;
  padding: 2px;
  text-align: center;
  

   }
   #inventory-adjustments th {
    padding-top: 2px;
    padding-bottom: 2px;
    text-align: center;
    background-color: #f67638;
    color: white;
    text-align: center;
    font-size: 0.5rem;
  }
   #items{
    width:85vw;
    font-size: 0.7rem;
   }

   #items td, #items th {

    border: 1px solid #ddd;
  padding: 2px;
  text-align: center;

   }

   #items th {
    padding-top: 2px;
    padding-bottom: 2px;
    text-align: center;
    background-color: #f67638;
    color: white;
    text-align: center;
  }

  .editbutt{
    float: center;
  }
 
    
  
  .editbutt.input-butt{
    float:left;
  }
  
  
  }
  @media  screen and (max-width: 400px){
    .editbutt{
      float: left;
    }
    
    .container{
      padding-left: 2px;
    }
    
    #inventorytable{
    
      border-collapse: collapse;
      width: 75%;
      font-size: 0.6rem;
      
      }
      
      #inventorytable td, #inventorytable th {
        border: 1px solid rgb(115, 106, 106);
        padding: 12px;
        text-align: left;
      }
      
      #inventorytable th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #fff;
        color: rgb(86, 79, 79);
        border-color:#ae9c9c;
      }
      #inventory-adjustments{

        width:82vw;
        font-size: 0.6rem;
    
       }
    
       #inventory-adjustments td, #inventory-adjustments th {
    
        border: 1px solid #ddd;
      padding: 2px;
      text-align: center;
      
    
       }
       #inventory-adjustments th {
        padding-top: 2px;
        padding-bottom: 2px;
        text-align: center;
        background-color: #f67638;
        color: white;
        text-align: center;
        font-size: 0.4rem;
      }
    
  
    
  }


  @media  screen and (max-width: 300px){
    
    #inventory-adjustments{
      width:80vw;
      font-size: 0.5rem;
     }
  
     #inventory-adjustments td, #inventory-adjustments th {
  
      border: 1px solid #ddd;
    padding: 2px;
    text-align: center;
  
     }
  
     #inventory-adjustments th {
      padding-top: 2px;
      padding-bottom: 2px;
      text-align: center;
      background-color: #f67638;
      color: white;
      text-align: center;
    }
    .container{
      padding-left: 0px;
    }


   
    .top-div{
      width:330px;
      display: flex;
      flex-direction: column;
    }
    .first-div{
      display: flex;
      flex-direction: column;
      width:300px;

    }
   
    #inventorytable{
    
     padding-left: 0px;
     width:60%;
      font-size: 0.4rem;
      
      }
      
      #inventorytable td, #inventorytable th {
        border: 1px solid rgb(115, 106, 106);
       
        text-align: left;
      }
      
      #inventorytable th {
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: left;
        background-color: #fff;
        color: rgb(86, 79, 79);
        border-color:#ae9c9c;
      }
    
    
    .second-div{
      width:40vw;
      padding-top: 0px;
    }
    
   #items{
    width:80vw;
    font-size: 0.5rem;
   }

   #items td, #items th {

    border: 1px solid #ddd;
  padding: 2px;
  text-align: center;

   }

   #items th {
    padding-top: 2px;
    padding-bottom: 2px;
    text-align: center;
    background-color: #f67638;
    color: white;
    text-align: center;
  }
    
  }

  
.form-errorinventory{
  font-size: 0.9rem;
  color: #b22b27;
  
  
}


  
.itemdrop{
  text-decoration: none;
  color:#141414;
}
.inventorydropdown{
  text-decoration: none;
  color:#141414;
}




.circle{
  padding-bottom:0px;
  margin-bottom: -2px;
  padding-left: 2px;
}

.row{ 
  display: flex;
flex-direction: row;}

.col-25radio{

  float: left;
  width: 25%;
   margin-top: -1px;
   font-size: 14px;

}
.col-25 .leftones {
  padding: 10px 2px 2px 2px;
  display: inline;
  font-size: 14px;
  
 }  
.col-25 .leftone {
  padding: 10px 2px 2px 2px;
  display: inline;
  font-size: 14px;
  
  
 }  

 .col-75 {
  float: center;
  width: 75%;
  margin-bottom: 10px;
  font-size: 14px;
}

.radio{
  margin-right: 100px;
  margin: 0 10px 0 0px;
  
 }

 .radiotwo{
  margin-right: 100px;
  margin: 0 10px 0 10px;
 }

 .col-25 {
    
  float: left;
  width: 25%;
   margin-top: 6px;
}
.leftone:after {
  content: " *";
  color: red;
}

.col-75 .inoneone {
  
  margin-top: 9px;

  display: inline;
  
 
  width: 330px;
    height: 76%;
    padding: 6px 12px;
    margin: 5px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    resize: vertical; 
padding-left: 10px;
 }  

 .col-25radio{

  float: left;
  width: 25%;
   margin-top: -1px;
   font-size: 14px;

}
@import url('https://fonts.googleapis.com/css2?family=inLato&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Lato', sans-serif;

}

.element2 h3 {
  /* Add your styles here */
  margin-left: 80px;
  margin-top: 44px;
  font-size: 24px; /* Adjust the font size as needed */
  font-weight: bold; /* Adjust the font weight as needed */
  color: #333; /* Adjust the text color as needed */
}
.purchaseorderfile img {
  width: 200px;
  height: 200px;
}

.reject-popup {
  position: fixed;
  top: 45%;
  left: 55%;
  transform: translate(-50%, -50%);
  width: 300px;
  padding: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
  z-index: 100;
}

.reject-popup h3 {
  margin: 0 0 10px;
  font-size: 18px;
  text-align: center;
}

.reject-popup textarea {
  width: 100%;
  height: 100px;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none;
  z-index: 101;
}

.reject-popup button {
  width: 100%;
  padding: 8px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #1976d2;
  color: #fff;
}

.reject-popup button:first-child {
  background-color: #d32f2f;
  color: #fff;
  margin-right: 5px;
}

.reject-popup button:last-child {
  background-color: #1976d2;
  color: #fff;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
  opacity: 0;
  font-size: 12px;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.tooltips {
  position: relative;
  display: inline-block;
}

.tooltips .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 100%;
  transform: translateX(10px) translateY(-50%);
  opacity: 0;
  font-size: 12px;
  transition: opacity 0.3s;
}

.tooltips .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #555;
}

.tooltips:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.calendarWrap {
  display: inline-block;
  position: relative;
  
}
.calendarWrap input[type=text] ,.calendarWrap input[type="number"],
.calendarWrap input[type="email"],.calendarWrap input[type="date"],.calendarWrap select[type="text"] {
 width:230px;
 height: 75%;
 padding: 7px 12px;
 margin: 5px ;
 display: inline-block;
 border: 1px solid #ccc;
 border-radius: 4px;
 box-sizing: border-box;
 
}
.calendarElement {
  position: absolute;
  left: 100%;
  transform: translateX(-50%);
  top: 40px;
  border: 1px solid #ccc;
  z-index: 999;
}

#supplyitem {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 0.8rem;
}

#supplyitem td, #supplyitem th {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
}

#supplyitem tr:nth-child(even){background-color: #f2f2f2;}

#supplyitem tr:hover {background-color: #ddd;}

#supplyitem th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #f67638;
  color: white;
  text-align: center;
}

.stylepara{
  font-size: 24px 
}
.imgstyle{ 
  width: 100px;
  height: 55px }

  .imgstyle2{ 
    width: 100px; 
    height: 55px; 
    margin-left:40px }
.logo{
  width: 100px;
height: 35px;
margin-left: 20px;
/* width: 250px;
height: 150px;
margin-left: 0px; */
}
element.style {
  color: rgb(129 53 53);
}
.icon{ color: '#000'}
h3{
  font-weight: 500;
  font-size: 26px;
  margin-left: 10px;
}
.home,
.products,
.team,
.reports {
  display: flex;
  height: 90vh;
  font-size: 1rem;
 
}
.paper{
   color: #141414;
cursor: pointer;
}
.para{
  font-size: 14px;
}

#requisation {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 0.8rem;
}

#requisation td, #requisation th {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
}

#requisation tr:nth-child(even){background-color: #f2f2f2;}

#requisation tr:hover {background-color: #ddd;}

#requisation th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #f67638;
  color: white;
  text-align: center;
}
.modal-container {
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction:column;
  align-items: flex-start;
  justify-content:flex-start;
  transition: 0.4s;
  margin-left: 16%;
  margin-right: 0%;
  flex-grow: 1;
  margin-top: 0px;
  overflow-y: scroll;
  padding: 0px;
 padding-bottom: 3.5rem;
}
.title{
  height: auto;
  background-color:#fff;
  border-bottom: none;
width: 100%;
border: 1px solid #eee;
box-sizing: border-box;
padding: 17px;
display: flex;
flex-direction: row;
justify-content: space-between;
max-height: 70px;
z-index: 400;
}
.title1{
  display: flex;
  margin-left: 20px;
  width: 20%;
}
.container{
  padding: 20px;
  padding-left: 30px;
  width: 100%;
  border-collapse: collapse;
}
.container2{
  padding: 0px;
  padding-left: 0px;
  width: 100%;

}
.containerh{
  padding:0px;
  padding-left: 0px;
  width: 100%;
  border-collapse: collapse;
}
.input-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
 padding: 10px;
 width: 65vw;
}
.input-container1{
  display: flex;
  flex-direction: row;
 padding: 10px;
justify-content: space-between;
width: 36.5vw;

}
.input-container-search{
  display: flex;
  flex-direction: row;

 padding: 10px;
justify-content: space-between;
width: 36.5vw;

}
.input-block_radio{
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  transition: 0.3s;


}
.input-label-radio{
  font-size: 14px;
  width: 49px;
   font-weight: 500;
   letter-spacing: 0.7px;
   color: #141414;
   transition: 0.3s;
   margin-top: 9px;
 }

.radio{  
  align-items: center;
  padding-left: 0;
  }
  .radiocontent{
    margin-top: 10px;
    box-sizing: border-box;
    width: 100px;
    width: 100px;
    font-size: 15px;

  }
.input-label {
  font-size: 14px;
 width: 180px;
  font-weight: 500;
  letter-spacing: 0.7px;
  color: #141414;
  transition: 0.3s;
  margin-top: 9px;
}
.input-block {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  transition: 0.3s;
margin-right: 40px;
width: 28vw;
}
.input-blockerror {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  transition: 0.3s;
  width: 28vw;
  margin-right: 40px;
}
.input-error{
 width: 250px;
  display: flex;
  flex-direction: column;
  
}
.file{
  margin-left: 20px;
}

.input-blockradio {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  transition: 0.3s;
margin-right: 65px;
justify-content: flex-start;
justify-content: space-between;
width: 18vw;
}
.input-block2 {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  transition: 0.3s;
margin-right: 0px;
}
.link{
  padding-bottom: 20px;
  margin-top: -15px;
}
.addlink{
  text-decoration: none;
  cursor: pointer;
  color: #428bca;
    background-color: transparent;
  
}

input[type=text] ,input[type="number"],input[type="email"],input[type="date"]{
  width:300px;
  height: 68%;
  padding: 7px 12px;
  margin: 5px ;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  
}.input-error input[type=text] ,.input-error input[type="number"],
.input-error input[type="email"],.input-error input[type="date"],.input-error select[type="text"] {
 width:230px;
 height: 75%;
 padding: 7px 12px;
 margin: 5px ;
 display: inline-block;
 border: 1px solid #ccc;
 border-radius: 4px;
 box-sizing: border-box;
 
}
/* .input-error input[type=text] ,.input-error input[type="number"],
 .input-error input[type="email"],.input-error input[type="date"]{
  width:220px;
  height: 68%;
  padding: 7px 12px;
  margin: 5px ;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  
} */
.input-error textarea{
  padding: 7px 12px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin: 5px ;
  width:230px;
}

.list{
  width: 350px;
  height: 100%;
  padding: 7px 0px;

  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-left: 10px;
}
textarea{
  padding: 7px 12px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-left:0px;
  width:300px;
}
.leftone:after {
  content: " *";
  color: red;
}
.textareas{
  padding: 7px 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-left: 0px;

}
.leave-button {
  padding: 0.4rem;
  outline: none;
  border: 0;
  color: rgb(255 255 255);
  border-radius: 5px;
  background: rgb(246 118 56);
  transition: 0.3s;
  cursor: pointer;
  font-family: "Nunito", sans-serif;
  margin-left: 20px;
}
.buttonDisplay{
  display: flex;
  width: 100%;
  justify-content: space-between;
 
}
.download{
  height: 35px;
  width: 35px;
  margin-right: 27px;
  color: #268ddd;
  font-size: 20px;
  cursor: pointer;
}
.allbuttonget{
  padding: 0.63rem ;
  outline: none;
  border: 0;
  color: #fff;
  border-radius: 5px;
  background: #f67638;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  transition: 0.3s;
  cursor: pointer;
  font-size: 13px;
  font-family: "Nunito", sans-serif;
  
}
.allbutton{
  background-color:#FFF;
  margin-left: 17px;
  padding: 8px;
  outline: none;
  border: 2px solid #f67638;
  border-radius: 5px;
  width: 82px;
 color:  #f67638;
  transition: 0.3s;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  font-family: "Nunito", sans-serif;
}
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 0.8rem;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 12px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f67638;
  color: white;
  text-align: center;
}

#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 0.8rem;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f67638;
  color: white;
  text-align: center;
}



#items {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 0.8rem;
}

#items td, #items th {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
}

#items tr:nth-child(even){background-color: #f2f2f2;}

#items tr:hover {background-color: #ddd;}

#items th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #f67638;
  color: white;
  text-align: center;
}

#inventory-adjustments th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f67638;
  color: white;
  text-align: center;
}

#inventory-adjustments {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 0.8rem;
}

#inventory-adjustments td, #inventory-adjustments th {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
}
.cards{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 100%;
  
}
.cards1{
 width: 25vw;
 height: 80vh;
 border: 1px solid #eee;
 box-sizing: border-box;
 background-color: #f7f2f2;
}
.package{
  padding: 20px;
  background-color: #d9f3f9;
  width: 85%;
}
.package1{
  padding: 20px;
  background-color: #d9f3f9;
 cursor: pointer;
}
.record{
 height: 20vh;
  margin: 12px;
  width: 90%;
  border: 1px solid #eee;
 box-sizing: border-box;
 background-color: #fff;
 font-size: 12px;
 text-align: center;
 color: #777;
 padding: 20px;
}
.sip{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
#Supplier {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 0.8rem;
}

#Supplier td, #Supplier th {
  border: 1px solid #ddd;
  padding: 12px;
}

#Supplier tr:nth-child(even){background-color: #f2f2f2;}

#Supplier tr:hover {background-color: #ddd;}

#Supplier th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f67638;
  color: white;
  text-align: center;
}
.dropback{
  background-color: #f4efec;
  text-decoration: none;
  text-align: center;
}
.drop{
  text-decoration: none;
  cursor: pointer;
  padding: 5px;
  z-index: 100;
}
.drop:hover{
  background-color: #f67638;
 
}
.navigation2{
  background:  #fff;
  height: auto;

  border-bottom: none;
width: 100%;
border: 1px solid #eee;
box-sizing: border-box;
padding: 5px;
display: flex;
flex-direction: row;
justify-content: flex-start;
max-height: 70px;
z-index: 400;
}
/* navigation button style */
.button-link {
  padding: 0.7rem ;
  outline: none;
  margin-right: 5px;
  border: 0;
  color: #268ddd;
  border-radius: 10px;
  background: #fcfcfc;
  transition: 0.3s;
  cursor: pointer;
  font-family: "Nunito", sans-serif;
}
.button-link:hover {
  background: #dadde0;
  color: #000;
}
.button-link.active {
  background: #268ddd;
  color: #fff;
}
/* navigation button style end*/


 /* navigation content style */
.fade{
  transition:opacity 0.1s linear;

}
.fade:not(show){
  display: none;
}
.tab-content > .tab-pane{
  display:none;
}
.tab-content > .active{
  display: block;

}
/* navigation content style end */

.address{
  display: flex;
  flex-direction: row;
}
.addr{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 36px;
}
.billing{
  padding: 20px;
  padding-left:0px ;
  font-size: 16px;
}
#contacttable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 0.8rem;
  margin-top: 30px;
  margin-bottom: 30px;
}

#contacttable td, #contacttable th {
  border: 1px solid rgb(185, 177, 177);
  padding: 12px;
}

#contacttable tr:nth-child(even){background-color: #f2f2f2;}

#contacttable tr:hover {background-color: #ddd;}

#contacttable th {
 
  text-align: left;
  background-color: #f8f8f8;
  color: #000;
  text-align: center;
}
 .inputtable{
  border: 0px solid transparent;
  box-shadow: none;
  width: 100%;
  height: 32px;
  background-color: #f8f8f8;
  margin: -38px;
  margin-left: 0px;
  margin-right: 0px;
  border: 0px solid #000;
  border-radius: 4px;
  text-align: center;
   
}

.note{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.notes{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width:100%;
  padding: 10px;
}
.pricediscount{
  background-color:#fbfafa;
  width:40vw;
  align-items: center;
  margin-right: 30px;
}
.total-label {
  display: inline-block;
  min-height: 28px;
  width: 70%;
  padding: 10px 1%;
}
.purchaseorderfile{
  padding: 30px;
  display: flex;
  flex-direction: column;
}
.modal-buttons{
  position: fixed;
  bottom: 0;
  margin: 0 0 0 -40px;
  box-shadow: 0 -4px 5px -3px rgb(0 0 0 / 10%);
  background-color: #fff;
  width: calc(100% - 200px);
  z-index: 100;

}
.save-button{
  display: flex;
  flex-direction: row;
padding:1rem;
}
.add-button{
  padding: 1rem ;
  outline: none;
  margin-bottom: 10px;
  border: 0;
  color: #fff;
  border-radius: 10px;
  background: #268ddd;
  transition: 0.3s;
  cursor: pointer;
  font-family: "Nunito", sans-serif;
}
.comment{
  height:70px;
  margin-top: 20px;
}

.top-div1 {
  border-radius: 1px;
  background-color: #f2f2f2;
  padding: 10px;
 padding-left: 23px;
  width: 82.3vw;
  display: flex;
  flex-direction: row;
 margin-left: -27px;
 margin-top: -20px;
}

.top-div2 {
  border-radius: 1px;
  background-color: #f2f2f2;
  padding: 10px;
 padding-left: 23px;
  width: 82.3vw;
 
  
 margin-left: -27px;
margin-top: 12px;
}

.input-block-text {
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  transition: 0.3s;
margin-right: 40px;
width: 28vw;
}
.input-each input[type=text] ,.input-each input[type="number"],
.input-each input[type="email"],.input-each input[type="date"],.input-each select[type="text"] {
 width:230px;
 height: 75%;
 padding: 7px 12px;
 margin: 5px ;
 display: inline-block;
 border: none;
 border-radius: 4px;
 box-sizing: border-box;
 background-color: #ffffff;
}
.input-each textarea{
  padding: 7px 12px;
  display: inline-block;
  border: none;
  border-radius: 4px;
  margin: 5px ;
  width:230px;
  background-color: #ffffff;
}
.input-label2 {
  font-size: 14px;
 width: 250px;
  font-weight: 500;
  letter-spacing: 0.7px;
  color: #141414;
  transition: 0.3s;
  margin-top: 9px;
}
.input-container-total{

  display: flex;
  flex-direction: row;
  justify-content: space-between;
 padding: 10px;
 padding-left: 30px;
 width: 40vw;
}
.firstbox{
  display: flex;
  flex-direction: row;
  width:100%;
  padding-top: 50px;
  
  
 }
 
.thirdbox{
  float: right;
  width:50%;
  height:100px;
  padding-left: 70px;

}
.topbox{
  float: right;
  padding-top: 0px;
  /* padding-right: 20vw; */
  width:100%;
  height:60px;
  display: flex;
  flex-direction: row;
 

}
.none{
  text-decoration: none;
  cursor: pointer;
  color: #428bca;
    background-color: transparent;
    padding-left: 10px;
   border: 0px;
   
}
.fon{
  font-weight:bolder;
  padding-bottom: 20px;
}

.iconimage{
  height: 1.5rem;
  width: 1.5rem;
  left:40px;
  padding: 4px;
  
}


.inputicon{
  padding-left: 20px;


}
.redbut{
  background-color: #b22b27;
  color:white;
  border:#b22b27;
  width:345px;
  height:40px;
}

.redbutton{
  padding-top: 15px;
}

.wrapper .inputicon{
  width:300px;
  height: 75%;
  padding: 7px 25px;
  margin: 5px ;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 0px;
  box-sizing: border-box;
  
}

.fc{
  padding-left: 5px;
  height:20px;
  width:20px;
}
.paragraph{
  
  margin-top: 18px;
}
.navbuttonone{
  
  
  border:0px;
  margin-right:20px;
  color:#b22b27;
  background-color: white;
  margin-top:7px;
  font-weight: 800;
 
  
  
}
.navbuttontwo{
  
  
  border:0px;
  margin-right:0px;
  color:white;
  background-color:#d32c3e;
  margin-top:3px;
  font-weight: 800;
  padding:2px 10px 7px 10px;
 
  
  
}
.navtwo{
  margin-right:0px;
}


.navbuttons{
  display: flex;
  flex-direction: row;
  float:right;
  margin-right: 0px;
  

}
.eachlabelsupplier{
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  width:70vw;
  margin-top: 18px;
  font-size: 14px;
  height:20px;
 }
 .eachinputtable{
  padding-left: 100px;
  width:250px;
 }
 .eachlabeltable{
  padding-right: 51px;
  width:160px;
 }
 .editbu {
 
  border-radius: 1px;
 
  padding: 10px;
 padding-left: 23px;
  width: 80vw;
  display: flex;
  flex-direction: row;
 margin-left: -10px;
 margin-top: -20px;
}
.input-bu {
  margin-right: 5px;
  padding: 0.6rem;
  outline: none;
  align-self: left;
  border: 0;
  color: #268ddd;
  border-radius: 10px;
  background: #ebe8e7;
  transition: 0.3s;
  cursor: pointer;
}
.input-but {
  margin-right: 5px;
  padding: 0.6rem;
  outline: none;
  align-self: left;
  border: 0;
  color: #fafafa;
  border-radius: 10px;
  background: #268ddd;;
  transition: 0.3s;
  cursor: pointer;
}


.containerfull{
  padding: 20px;
  padding-left: 30px;
  width: 100%;
  border-collapse: collapse;
  position:fixed;
}
/* .topbox{
  width:100%;
  height:45px;
  display: flex;
  flex-direction: row;
  padding-top: 0px;


} */

.headertopbox{
  background-color: #566573 ;
  /* background-color: #ccc; */
  height:60px;
  width:39%;
  display: flex;
  flex-direction: column;
  margin-top: 0%;

}
.signintopbox{
  /* float: right; */
 
  height:60px;
  width:56%;
  padding-top: 15px;
  
  
  
}

.float{
  float: right;
  padding-right: 20px;
}
.none{
  text-decoration: none;
  cursor: pointer;
  color: #428bca;
    background-color: transparent;
    padding-left: 10px;
   border: 0px;
   
}

.firstbox{
  display: flex;
  flex-direction: row;
  width:100%;
  padding-top: 0px;
  height:85%;
  
  
  
 }
 .secondbox{
  display: flex;
  flex-direction: column;
  background-color: #566573 ;
  width:39%;
  padding-top: 0px;
  height:680px;
  margin-bottom: 0px;
  

 }
.thirdbox{
  float: right;
  /* width:70%; */
  
  /* padding-left: 70px; */
  margin-top: 71px;
    width: 50%;
    float: right;
    height:680px;

}

.fon{
  font-weight:bolder;
  padding-bottom: 20px;
  
  padding-left: 250px;
}
.fontwo{
 
  padding: 0 30px 70px;
    width: 500px;
    margin: auto;

}
.iconimage{
  height: 1.5rem;
  width: 1.5rem;
  left:40px;
  padding: 4px;
  
}

.wrapper{
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row;
  height:53px;
  width:110%;

  justify-content: space-between;
}


.inputicon{
  padding-left: 20px;


}
.redbut{
  background-color: #b22b27;
  color:white;
  border:#b22b27;
  width:396px;
  height:50px;
  font-size: 15px;
}

.redbutton{
  padding-top: 15px;
}
.bs{
  position: absolute;
  padding-left: 10px;

  height:30px;
  width:30px;
  margin-top:15px;
}
.wrapper .inputicon{
  width:395px;
  height: 105%;
  padding: 7px 30px;
  margin: 5px ;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 0px;
  box-sizing: border-box;
  font-size: 16px;
}
.redbut{
  background-color: #b22b27;
  color:white;
  border:#b22b27;
  width:396px;
  height:50px;
  font-size: 15px;
}
.redbutton{
  padding-top: 15px;
}
.paragraph{
  padding-top: 10px;
}
.homeone {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
  width: 100%;
  padding: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../src/components/Images/backgroundimage.jpg);
}

.sign {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 40px;
  height: 66vh;
  width: 39vw;
  background-color: white;
  box-shadow: 0 10px 25px rgb(0 0 0 / 5%),
              0 20px 48px rgb(0 0 0 / 5%),
              0 1px 4px rgb(0 0 0 / 10%);
}
.signone {
  display: flex;
  flex-direction: column;
  height: 40vh;
  width: 40vw;
  margin: auto; /* Add this line */
  justify-content: center; /* Add this line */
  align-items: center; /* Add this line */
}
.signtwo{
  height:450px;
  width:30vw;
}
.vl {
  border-left: 1px solid #ccc;
  height: 563px;
}
.heading{
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 0%;
 
}
.wrapping {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.form{height: 35vh;
  width: 90%; /* Adjust the width for smaller screens */
  margin-top: 2vh;}
.wrap {
  height: 35vh;
  width: 100%; /* Adjust the width for smaller screens */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5vh;
}
.wrap .signinput {
  width: 370px;
  height: 20%;
  padding: 7px 12px;
  margin: 5px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-left: 0%;
}
.wrap .bluebutt {
  color: white;
  width: 370px;
  height: 20%;
  padding: 7px 12px;
  margin: 5px;
  display: inline-block;
  border: #159AFF;
  background-color: #159AFF;
  border-radius: 4px;
  box-sizing: border-box;
  margin-left: 0%;
}.pass{
  float:center;
  
  margin-top: 15px;
}
.fullcontainer{
  padding: 20px;
  padding-left: 0px;
  width: 100%;
  border-collapse: collapse;
}


.containerfull{
  padding: 20px;
  padding-left: 30px;
  width: 100%;
  border-collapse: collapse;
  position:fixed;
}
.modal-containerone{
  width: 100%;
 
  /* background: #fff; */
  display: flex;
  flex-direction:column;
  align-items: flex-start;
  justify-content:flex-start;
  transition: 0.4s;
  margin-left: 16%;
  margin-right: 0%;
  flex-grow: 1;
  margin-top: 0px;
  overflow-y: scroll;
  padding: 0px;
 padding-bottom:9.5rem;

}

.fullcontainerone{
  padding: 20px;
  width: 100%;
  border-collapse: collapse;
  height: 100%;
}

.eachlabelemployee{
  display: flex;
  flex-direction: row;
  padding-left: 10px;
  width:32vw;
  margin-top: 18px;
  font-size: 14px;
  height:20px;
 }
 .eachinputemployee{
  padding-left: 100px;
  width:183px;
 }

 .eachlabeloneemployee{
  padding-right: 30px;
  width:124px;
 }
 .firstsessionemployee{
  display: flex;
  flex-direction: row;
  margin-top: 15px;
 
 }
 .secondsessionemployee{
  display: flex;
  flex-direction: column;
  
 }
  .form-erroremployee{
  font-size: 0.9rem;
 color: #b22b27;

 }
 .form-erroremployees{
  font-size: 1.5rem;
 color: #0dbe42;

 }
 .form-errors{
  font-size: 1.5rem;
 color: #b22b27;

 }
 .form-error{
  font-size: 0.9rem;
  color: #b22b27;

 }
 .input-employeecontainer{
  display: flex;
  flex-direction: row;
 
 padding: 4px;
 
}
.input-blockemployee {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  transition: 0.3s;
margin-right: 40px;
width: 35vw;
}
.input-blockemployees {
  display: flex;
  flex-direction: row;
  border-radius: 4px;
  transition: 0.3s;
margin-right: 40px;
width: 35vw;
}
.input-labelemployee {
  font-size: 14px;
 width: 180px;
  font-weight: 500;
  letter-spacing: 0.7px;
  color: #141414;
  transition: 0.3s;
  margin-top: 9px;
}

.input-blockemployee input[type=text],.input-blockemployee  input[type="number"], .input-blockemployee input[type="email"], .input-blockemployee input[type="date"], .input-blockemployee input[type="password"] {
  width: 230px;
  height: 72%;
  padding: 7px 12px;
  margin: 5px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.input-blockemployee select[type="text"]{
  width: 230px;
  height: 72%;
  padding: 7px 12px;
  margin: 5px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}
.input-blockemployees  input[type="number"]{
  width: 230px;
  height: 40%;
  padding: 7px 12px;
  margin: 5px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;

}
.show-button{
  margin-left: 5px;
  height: 30px;
}
.input-blockemployee textarea{
  width:230px;
  height: 60%;
  padding: 7px 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-left: 6px;
}
.radioemployee{
  
  margin: 4px 4px 2px 0px;
  margin-left: 30px;
  
 }
 .radiotype{
  margin-left:80px;
 }
 
 .marginhead{
  margin-right: auto;
  width:93%;
  
 }

 .modal-containerlogin {
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction:column;
  align-items: flex-start;
  justify-content:flex-start;
  transition: 0.4s;
  margin-left: 1%;
  margin-right: 0%;
  flex-grow: 1;
  margin-top: 0px;
  overflow-y: scroll;
  padding: 0px;
 padding-bottom: 3.5rem;
}
.containerfulllogin{
  padding: 0px;
  padding-left: 30px;
  width: 100%;
  border-collapse: collapse;
  position:fixed;
}
.linespan{
  background-color: #f1f1f1;
    width: 100%;
    height: 2px;
    border-radius: 1px;
    margin-bottom: 20px;
    position: relative;
}

.linespantwo{
  position: absolute;
    top: -8px;
    text-align: center;
    width: 100%;
    margin: auto;
}
.linespantwo span{
  background: #fff;
    color: #707070;
}
.or{
  margin-top: 20px;
  margin-left: 230px;
}
.signlinkedin{
  margin-top: 30px;
  margin-left: 50px;

}
.socialmedia{
  margin-left: 50px;
  margin-top:15px;
}

.form-errormain{
  font-size: 0.8rem;
 color: #b22b27;
 padding-left: 2px;

 }
 /* .input-errormain input[type=text] ,.input-error input[type="number"],
 .input-error input[type="email"],.input-error input[type="date"]{
  
  width: 395px;
    height: 200%;
    padding: 7px 30px;
    margin: 5px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 0px;
    box-sizing: border-box;
    font-size: 16px;
    
} */
.input-errormain .inputicon {
  width: 395px;
  height: 105%;
  padding: 7px 30px;
  margin: 5px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 0px;
  box-sizing: border-box;
  font-size: 16px;
  margin-top:28px;
  
}
.input-errormain{
  width: 250px;
   display: flex;
   flex-direction: column;
   height:165%;
   
 }
 .homemain{
  display: flex;
  height: 100vh;
  font-size: 1rem;
  
 
 
 }
 .modal-containerhome{
  width: 100%;
  
  background-color: #fff;

  display: flex;
  flex-direction:column;
   align-items: flex-start;
  justify-content:flex-start;
  transition: 0.4s;
  margin-left: 0%;
  margin-right: 0%;
 
  flex-grow: 1;
  
  overflow-y: scroll;
  padding: 0px;
 
 
 


 }
 .modal-containerhometwo{
  width: 100%;
  
  background-color: #ffeee6;

  display: flex;
  flex-direction:column;
   align-items: flex-start;
  justify-content:flex-start;
  transition: 0.4s;
  margin-left: 0%;
  margin-right: 0%;
 
  flex-grow: 1;
  
  
  padding: 0px;
 
 background-color:#FDEDEC 

 }

 .containerhome{
  padding: 20px;
  padding-left: 30px;
  width: 100%;
  border-collapse: collapse;
  

 }

 .homemainone{
  display: flex;
  height: 100vh;
  font-size: 1rem;
  
  
 
 
 }
 .navbar{
  height:100%;
  min-width:85%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.nav-item{
  font-size:16px;
  font-weight:800;
  margin: 0px 3px;
  text-decoration: none;
  transition: 0.2s;
  color:rgb(69,69,69)
}

.nav-btn{
  cursor: pointer;
  border-radius: 20px;
  padding: 10px 20px;
}
.navlinktop{
  margin-top: -15px;
  float: right;
}

.main-content{
  margin-top: 200px;
  margin-left: 400px;
}
.bbslogo{
  float: left;
  display: flex;
  margin-top:2px ;
  margin-left:60px;
}

.modal-buttonsemployee{
  position: fixed;
bottom: 0;
margin: 0 0 0 -40px;
box-shadow: 0 -4px 5px -3px rgb(0 0 0 / 10%);
background-color: #fff;
width: calc(100% - 200px);
z-index: 3;
height:50px;
}

.headerbox{
  
  width:100%; 
  height:30px;
  margin-top: 0px;
}
.buttonsdemo{
  display: flex;
  flex-direction: row;
  float:center;
  margin-left: 100px;
  
}
.demo{
  margin-right: 30px;
  padding: 10px 30px 10px 30px;
  border:1px;
  border-radius: 4px;
  background-color: #d32c3e;
  color:white;
  font-weight: 600;
  
}

.signbutton{
  margin-right: 30px;
  padding: 10px 30px 10px 30px;
  border:1px;
  border-radius: 4px;
  border:1px solid #d32c3e;
  background-color:#FDEDEC;
  border-radius: 4px;
  color:#d32c3e;
  font-weight: 600;

}
.mega-menu{

  top:80px;

   width:600px;
 position:absolute; 

background-color: white;
display: flex;

list-style: none;
left:14%;


}
.megamenu-items{
  flex-basis: 33%;
}
.navitemsbar {
  height: 10vh;
 
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top:-27px;
  position: relative;
}

.nav-itemsbar {
  display: flex;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: flex-end;
  margin-right: 2rem;
  margin-top: 4px;
}

.homedropdown {
  display: flex;
  align-items: center;
  height: 10vh;
}

.homedropdown a {
  text-decoration: none;
  color: rgb(22, 21, 21);
  font-size: 1.1rem;
  margin-right: 2rem;
  padding: 6px 16px;
  border-radius: 5px;
}

.homedropdown a:hover {
  background: white;
}
.Features-submenu{
width: 15rem;
position: absolute;
top: 65px;
list-style: none;
text-align: start;
margin-top:20px;

} 

.Features-submenu li {
  background: white;
  cursor: pointer;
  
  
}

.Features-submenu li a:hover {
  background: white;
}

.Features-submenu.clicked {
  display: none;
}

.featuredropdown {
  display: block;
  width: 100%;
  height: 100%;
  text-decoration: none;
  color: white;
  padding: 16px;
  
  
}
.aisearch{
  margin-right:30px;
  margin-top:5px;
}

#rc {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 0.8rem;
}

#rc td, #rc th {
  border: 1px solid #ddd;
  padding: 12px;
}

#rc tr:nth-child(even){background-color: #f2f2f2;}

#rc tr:hover {background-color: #ddd;}

#rc th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f67638;
  color: white;
  text-align: center;
}
#leave {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 0.8rem;
}

#leave td, #leave th {
  border: 1px solid #ddd;
  padding: 12px;
  text-align: center;
}

#leave tr:nth-child(even){background-color: #f2f2f2;}

#leave tr:hover {background-color: #ddd;}

#leave th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #f67638;
  color: white;
  text-align: center;
}


.dashboard{
  display: flex;
  
  height:35vh;
  margin-top: -10px;
  margin-left: 0%;
 
  width:100%;
 
  
 }
 .time{
  margin-left: 8px;
  width: 230px;
  height:30px;
  padding:8px;
  border: 1px solid #ccc;
  border-radius: 4px;
 }
/* .dashboardone{
  height:35vh;
  background-color: #f5faff;
  margin-top: 10px;
  margin-left:0%;
  width:70%;
  border:1px solid #ececec;
  border-radius:1px ;
  display:flex;
  flex-direction: row;
} */
.dashboardone {
  height: 35vh;
  background-color: #f5faff;
  margin-top: 10px;
  margin-left: 0;
  width: 100%;
  border: 1px solid #ececec;
  border-radius: 1px;
  display: flex;
  flex-direction: row;
}
.dashboardbutton{
  height:20vh;
  width:8vw;
  background-color: white;
  border:1px solid #ececec;
  border-radius: 4px;
  margin-right: -26px;
  margin-top:8%;
  margin-left:30px;
}
.dashboardtwo{
  height:35vh;
  background-color: #f5faff;
  margin-top: 10px;
  width:40%;
  border:1px solid #ececec;
  border-radius:1px ;
}
.qty{
  color:#8d99ae;
  font-weight:100;
  font-size:13px;
  text-align: center;
  margin-top: 10px;
  
}

.summary{
  font-size:16px;
  font-weight: 100;
  text-align: left;
  margin-top:30px;
  margin-left:20px;
}

.inputinventorymain1 {
  height:7vh;
  width:80%;
 border:1px solid #566573;
border-radius: 5px;
 padding: 5px;
 display: flex;
 flex-direction: row;
 justify-content: space-between;
}

.inputinventorymain{
  margin-top:25px;
  margin-left:20px;
}
#dashboardtable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
 
  color: #000;
  text-align: center;
}

#dashboardtable {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 37vw;
  height:25vh;
  font-size: 0.8rem;
}

#dashboardtable td, #dashboardtable th {
  border: 1px solid #ececec;
  padding: 12px;
  text-align: center;
}

.tableon{
  margin-top:12vh;
  display: flex;
  flex-direction: row;
  margin-left:4%;
  margin-right: 3%;
}
.tableone{
  position: relative;
  margin-right: 20px;
}
#dashboardtabletwo th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
 
  color: #000;
  text-align: center;
}

#dashboardtabletwo {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 37vw;
  height:25vh;
  font-size: 0.8rem;
}

#dashboardtabletwo td, #dashboardtabletwo th {
  border: 1px solid #ececec;
  padding: 12px;
  text-align: center;
}
#dashboardtabletwo tbody{
  height:70px;
}

.purchaseordertable{
  height:50vh;
 
  width:100%;
  display: flex;
  flex-direction: row;
  margin-top: 7vh;
  

  

}

.purchaseordertabletwo{
  height:35vh;
border:1px solid #ececec;
  width:60%;
  margin-right:5%;
}
.purchaseordertableone{
 
  width:60%;
  height:35vh;
  margin-right: 20px;
  border:1px solid #ececec;
  margin-left:4%;
}

.calenderinput{
  display: flex;
  flex-direction: row;
}

.calender{
  margin-top: 5px;
  margin-right: 10px;
}

.salestable{
  width:91%;
  margin-left: 4%;
  margin-right: 10%;
  height:55vh;
  
  margin-top:-8%;
  border:1px solid #ececec;

}

.horizontalone{
  
  border: 1px solid #ececec;
}

#Pucc{
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 0.8rem;
}

#Pucc td, #Pucc th {
  border: 1px solid #ddd;
  padding: 12px;
}

#Pucc tr:nth-child(even){background-color: #f2f2f2;}

#Pucc tr:hover {background-color: #ddd;}

#Pucc th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f67638;
  color: white;
  text-align: center;
}
#Service{
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 0.8rem;
}

#Service td, #Service th {
  border: 1px solid #ddd;
  padding: 12px;
}

#Service tr:nth-child(even){background-color: #f2f2f2;}

#Service tr:hover {background-color: #ddd;}

#Service th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f67638;
  color: white;
  text-align: center;
}

#permittable{
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 0.8rem;
}

#permittable td, #permittable th {
  border: 1px solid #ddd;
  padding: 12px;
}

#permittable tr:nth-child(even){background-color: #f2f2f2;}

#permittable tr:hover {background-color: #ddd;}

#permittable th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f67638;
  color: white;
  text-align: center;
}


#invoice{
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 0.8rem;
}

#invoice td, #invoice th {
  border: 1px solid #ddd;
  padding: 12px;
}

#invoice tr:nth-child(even){background-color: #f2f2f2;}

#invoice tr:hover {background-color: #ddd;}

#invoice th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f67638;
  color: white;
  text-align: center;
}

.dropdown {
  /* margin-top:38px; */
  margin-left:-100px;
  background-color:  white;
  padding: 1rem;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index:1300;
 
}
.logoutbutton {
  background-color: #f67638;
  color: white;
  border: none;
  padding: 0.6rem 1rem;
  cursor: pointer;
  border-radius: 5px;
  margin-top: -4px;
}

.dropdown p {
  margin: 0;
  margin-bottom: 0.5rem;
}

.dropdown button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  
}

.dropdown button:hover {
  background-color: #d32f2f;
}
#tooltip{
  position:relative;
  cursor:pointer;
  padding:7px;
  font-size:14px;
  padding-left: 2px;
  

}
/* #Tooltiptext{
  position: absolute;
  left:50%;
  top:0;
  transform: translateX(-50%);
  background-color: black;
  color: #fff;;
  white-space: nowrap;
  padding: 5px 15px ;
  border-radius: 7px;
  visibility:hidden;
  opacity:0;
  transition: transition 0.5s ease;
  height:82px;
  width:210px;
  font-size:0.8rem;
  margin-left:60px;
  border: 2px  solid#000;
  
  
  
}
#Tooltiptext::before{
  content:"";
  position: absolute;
  left: 30%;
  top:100%;
  transform:translateX(-50%);
  border:15px solid;
  border-color: #000 #0000 #0000 #0000;
  
}

#tooltip:hover #Tooltiptext{
  top:-260%;
  visibility: visible;
  opacity:1;

} */
#Sales {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
  font-size: 0.8rem;
}

#Sales td, #Sales th {
  border: 1px solid #ddd;
  padding: 12px;
}

#Sales tr:nth-child(even){background-color: #f2f2f2;}

#Sales tr:hover {background-color: #ddd;}

#Sales th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #f67638;
  color: white;
  text-align: center;
}
.signs{
  background-color: #707070;
}
.logo1{
  width: 120px;
height: 29px;
margin-top: 0px;

/* width: 250px;
height: 150px;
margin-left: 0px; */
}
.input-button {
  padding: 0.6rem ;
  outline: none;
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
  border: 0;
  color: #fff;
  border-radius: 5px;
  background: #268ddd;
  transition: 0.3s;
  cursor: pointer;
  font-family: "Nunito", sans-serif;
  margin-left: 20px;
}
.input-button:hover {
  background: #0b5893;
}
.headerdiv{
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
}
.parentdiv{
  display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    /* height: 100px; */
}

.element1, .element2 {
  display: flex;
  flex-direction: row;
 /* height: 100px; */
 content: initial;
 width: 100%;
}
.element11, .element22 {
  display: flex;
  flex-direction:column;
 /* height: 100px; */
 content: initial;
 width: 40%;
}
.input-gst{
  width: 150px;
   display: flex;
   flex-direction: column;
   
 }
 .logo2{
  width: 150px;
  height: 60px;
  margin-left: 10px;
  margin-top: 20px;
  }
.input-gst input[type=text] ,.input-gst input[type="number"],
.input-gst input[type="email"],.input-gst input[type="date"],.input-gst select[type="text"] {
 width:130px;
 height: 75%;
 padding: 7px 12px;
 margin: 5px ;
 display: inline-block;
 border: 1px solid #ccc;
 border-radius: 4px;
 box-sizing: border-box;
 
}
.select-container {
  width: 400px;
  margin-left: 0px;
  display: inline-block;
}

.select__control {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  font-size: 14px;
  height: 34px;
  width: 400px;
  cursor: pointer;
}

.select__control:hover {
  border-color: #a3a3a3;
}

.select__control--is-focused {
  border-color: #2684ff;
  box-shadow: 0 0 0 1px #2684ff;
}

.select__value-container {
  flex: 1;
  padding-left: 8px;
}

.select__single-value {
  font-size: 14px;
}

.select__indicator {
  padding-right: 8px;
}

.select__indicator-separator {
  display: none;
}

.select__menu {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: #ffffff;
  border: 1px solid #d4d4d4;
  border-radius: 4px;
  margin-top: 4px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  max-height: 400px;
  overflow-y: auto;
}

.select__menu-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

.select__option {
  font-size: 14px;
  padding: 8px;
  cursor: pointer;
}

.select__option--is-selected {
  background-color: #f0f0f0;
}

.select__option--is-focused {
  background-color: #2684ff;
  color: #ffffff;
}

.select__option--is-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.select__loading-indicator {
  position: absolute;
  right: 8px;
  top: 8px;
}

.select__loading-indicator-icon {
  animation: spin 1s linear infinite;
}
.pagination {
  display: flex; /* Use flexbox for the container */
  align-items: center; /* Center items vertically */
  margin-top: 20px;
}
.page-info {
  margin-right: -15px; /* Add some spacing between the page info and buttons */
  margin-left: 15px; 
}
.Search-field{
  width: 50vw;
  margin-left: 2%;
}
@media only screen and (max-width: 1050px) {
  /* Styles for screens smaller than or equal to 768px */
  .sign {
    margin-left:17%;
    width: 60vw;
  }
.signone {
    height: auto;
    width: 50vw;
    max-width: 50vw;
  }
.heading {
    margin-left: -5%; /* Adjust the margin for smaller screens */
  }
.wrapping {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
.wrap {
    margin-left: 0%;
    height: auto;
    width: 100%; /* Adjust the width for smaller screens */
    margin-top: 3vh; /* Adjust the margin for smaller screens */
  }
.wrap .signinput {
    width: 100%;
    height: auto;
    margin: 10px 0;
  }
.wrap .bluebutt {
    width: 100%;
    height: auto;
    margin: 10px 0;
  }
}
@media only screen and (max-width: 768px) {
  /* Styles for screens smaller than or equal to 768px */
  .leave-button {
    margin-left: 10px;
    padding: 0.6rem;
    height: 50px;
  }

  .sign {
    margin-left:8%;
    width: 80vw;
  }
.signone {
    height: auto;
    width: 50vw;
    max-width: 50vw;
  }
.heading {
    margin-left: 5%; /* Adjust the margin for smaller screens */
  }
.wrapping {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
.wrap {
    margin-left: -10%;
    height: auto;
    width: 120%; /* Adjust the width for smaller screens */
    margin-top: 3vh; /* Adjust the margin for smaller screens */
  }
.wrap .signinput {
    width: 100%;
    height: auto;
    margin: 10px 0;
  }
.wrap .bluebutt {
    width: 100%;
    height: auto;
    margin: 10px 0;
  }
}

@media only screen and (max-width: 480px) {

#requisation td, #requisation th {
    padding: 2px;
    padding-top: 8px;
    padding-bottom: 8px;
    text-align: center;
}
 h3 {
    font-weight: 500;
    font-size: 15px;
    margin-left: 0px;
}
  .inputtable {
    border: 0px solid transparent;
    box-shadow: none;
    width: 100%;
    height: 30px;
    background-color: #f8f8f8;
    margin: -38px;
    margin-left: 0px;
    margin-right: 0px;
    border: 0px solid #000;
    border-radius: 0px;
    text-align: center;
    font-size: 9px;
    font-weight: 700;
  }
  .logo2 {
    width: 80px;
    height: 45px;
    margin-left: 10px;
    margin-top: 20px;
}
  .element2 h3 {
    margin-left: -4px;
    margin-top: 44px;
    font-size: 14px;
    font-weight: bold;
    color: #333;
}
  .leave-button {
    margin-left: 10px;
    padding: 0.6rem;
    height: 50px;
  }

  .dashboardone {
    height: auto;
    margin-top: 10px;
    margin-left: 0;
    width: 100%;
    border: 1px solid #ececec;
    border-radius: 1px;
    display: flex;
    flex-direction: column;
  }
  .dashboard {
    display: flex;
    height: 85vh;
    margin-top: -10px;
    margin-left: 0%;
    width: 100%;
}

.dashboardbutton{
  width:50vw;
}
.dashboardtwo{
  height:auto;
  width: 50%;
}
.inputinventorymain1 {
  height:10vh;
 flex-direction: column;
 justify-content: space-between;
}
  .modal-container {
    margin-left: 0%;
  }
  .logo{
    width: 95px;
    height: 35px;
    margin-left: 0px;
  }
  .logo1 {
    padding: 9px;
    width: 75px;
    height: 40px;
    margin-top: -8px
  }
  .para {
    padding: 3px;
    font-size: 14px;
  }
  
  /* Styles for screens smaller than or equal to 480px */
  .sign {
    margin-top: -30vh;
    margin-left: 0%;
    width: 100vw;
    height:135%;
    max-height: 135%;
}
.signone {
  height: auto;
  width: 80vw;
  max-width: 80vw;
  }
.heading {
    margin-left: -10%; /* Adjust the margin for smaller screens */
  }
.wrapping {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -50px;
  }
.wrap {
    margin-left: -10%;
    height: auto;
    width: 120%; /* Adjust the width for smaller screens */
    margin-top: 2vh; /* Adjust the margin for smaller screens */
  }
 .wrap .signinput {
    padding: 12px 15px;
  }
.wrap .bluebutt {
    padding: 15px 10px;
  }
  .tableone {
    margin-right: 15px;
}
  .tableon {
    margin-top: 3vh;
}
#dashboardtable {
  width: 53vw;
}
.purchaseordertable {
  flex-direction: column;
  margin-top: 2vh;
}
.purchaseordertableone {
  width: 94%;
  height: 50vh;
}
.purchaseordertabletwo {
  height: 50vh;
  width: 94%;
  margin-right: 0%;
  margin-left: 4%;
}
.salestable {
  margin-top: 12%;
}
.input-container{
  flex-direction:column;
}
.top-div1 {
  width: 104.3vw;
  flex-direction: column;
}
.modal-buttons {
  margin: 0 0 0 0px;
  width: 100%;
  z-index: 3;
}
.input-blockerror{
  width: 90vw;
}
.input-block {
  width: 90vw;
}
.input-employeecontainer {
  flex-direction: column;
}
.input-blockemployee {
  width: 90vw;
}
.input-container-search {
  width: 93vw;
}
.input-block_radio {
  width: 53vw;
}
.addlink {
  width: 53vw;
}
.title1 {
  width: 45%;
}
#Supplier {
  width: 50%;
  font-size: 0.7rem;
}
#Supplier td, #Supplier th {
  border: 1px solid #ddd;
  padding: 2px;
  text-align: center;
}
#Supplier th {
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: center;
  background-color: #f67638;
  color: white;
  text-align: center;
}
#Sales {
  width: 50%;
  font-size: 0.7rem;
}
#Sales td, #Sales th {
  border: 1px solid #ddd;
  padding: 2px;
  text-align: center;
}
#Sales th {
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  background-color: #f67638;
  color: white;
  text-align: center;
}
#customers {
  width: 100%;
  font-size: 0.9rem;
}
#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 2px;
  text-align: center;
}
#customers th {
  padding-top: 8px;
  padding-bottom: 8px;
  text-align: center;
  background-color: #f67638;
  color: white;
  text-align: center;
}
#invoice {
  width: 100%;
  font-size: 0.9rem;
}
#invoice td, #invoice th {
  border: 1px solid #ddd;
  padding: 2px;
  text-align: center;
}
#invoice th {
  padding-top: 2px;
  padding-bottom: 2px;
  text-align: center;
  background-color: #f67638;
  color: white;
  text-align: center;
}
.imgstyle{ 
  width: 100px;
  height: 55px ;
  margin-left: 50vw;
}
.input-label2 {
  font-size: 14px;
  width: 43px;
  font-weight: 500;
  letter-spacing: 0.7px;
  color: #141414;
  transition: 0.3s;
  margin-top: -25px;
  margin-left: -65px;
}
.input-gst {
  width: 150px;
  display: flex;
  flex-direction: column;
  margin-top: -30px;
}
.page-info {
  margin-right: 14px;
  margin-left: 15px;
}
  .imgstyle2{ 
    width: 100px; 
    height: 55px; 
    margin-left:40px;
    margin-left: 45vw;
   }
   .allbutton {
    margin-left: 4px;
    padding: 3px;
    width: 70px;
    font-size: 13px;
    height:40px;
}
.reject-popup {
  position: fixed;
  top: 50%;
  left: 47%;
}
.logoutbutton {
  
  padding: 0.5rem 1rem;
  margin-top: -4px;
}
.parentdiv{
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    width: 100%;
    /* height: 100px; */
}

.element1, .element2 {
  display: flex;
  flex-direction: row;
 /* height: 100px; */
 content: initial;
 width: 100%;
}
.element11, .element22 {
  display: flex;
  flex-direction:column;
 /* height: 100px; */
 content: initial;
 width: 50%;
}
.purchaseorderfile img {
  /* Add your mobile-specific styles for the image here */
  /* For example, you can change the width and height of the image to fit better on mobile devices */
  width: 120px;
  height: 120px;
}
.note {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 20px;
}
}

@media only screen and (max-width: 335px) {
  
  .leave-button {
    margin-left: 10px;
    padding: 0.6rem;
    height: 50px;
  }

  .imgstyle{ 
    width: 100px;
    height: 55px;
    margin-left: 65vw;
   }
  
    .imgstyle2{ 
      width: 100px; 
      height: 55px; 
      margin-left:40px ;
      margin-left: 40vw;
    }
    .stylepara{
      font-size: 22px 
    }
}


.totalrow{
    display: flex;
flex-direction: row;

}
.col{
float: left;
  width: 20%;
   margin-top: 6px;
}
.inputmanufacturer{
    width: 320px;
      height: 68%;
      padding: 7px 12px;
      margin: 5px;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 1px;
      box-sizing: border-box;
   }

   .colbox{
    float:right;
    width:71%;
    margin-bottom: 10px;
    padding-left: 30px;
   
    
  }